.main-wrap {
  @media (max-width: 960px) {
    .main-content {
      //margin: 0 !important;
      //max-height: calc(100vh - 128px) !important;
      //padding: 8px !important;
    }
  }
  @media (max-width: 600px) {
    .main-content {
      margin: 0 !important;
      margin-top: 82px !important;
      margin-bottom: 62px !important;
      padding: 0px !important;
      max-height: unset !important;

      .single-story{
        flex: unset!important;
        max-width: 100%;


        .single-story-content{
          flex:unset!important;
          max-width: 100%;
        }
        .spacer{
          display: none;
        }
      }

      

      .card-no-padding {
        //padding: 0px !important;

        .ant-card-body {
          padding: 0px !important;
        }
      }
    }
  }

  .home-wrap {
    @media (max-width: 960px) {
      .feed-wrap {
        max-width: 100% !important;
      }
    }

    @media (max-width: 600px) {
      .create-story-wrap {
        .ant-card .ant-card-body {
          padding: 16px !important;
        }

        .row1 {
          gap: 10px;

          .card-no-padding {
            padding: 0 !important;

            .ant-card-body {
              padding: 10px !important;
            }
          }
        }
      }
    }

    .FeedItem-wrap {
      @media (max-width: 600px) {
        .feed-item {

          .post-created-at {
            font-size: 12px !important;
          }

          .commentbox {
            // margin-bottom: 0px !important;

            .comment-avatar {
              width: 38px !important;
              height: 38px !important;
            }
          }
        }
      }
    }
  }
}

.user-info {
  &:hover {
    .user-name {
      text-decoration: underline !important;
    }
  }
}


.story-comments-wrap {
  .user-name {
    &:hover {
      text-decoration: underline !important;
    }
  }

  .ant-input-affix-wrapper {
    padding: 0px;
    background-color: unset;
    border: unset;

    input {
      background-color: rgb(242, 242, 245);
      padding-left: 10px;
      height: 40px;
      border-radius: 12px 0px 0px 12px;

    }

    .ant-input-suffix {
      background-color: rgb(242, 242, 245);
      margin: unset;
      padding-right: 10px;
      border-radius: 0px 12px 12px 0px;
    }
  }
}

.build-profile{
  @media (max-width: 600px) {
    align-items: start!important;
    padding-top: 100px;
    .steps-titles{
      .ant-typography{
        word-break: keep-all;
      }
    }
    .questionwidth{
      width: 100%!important;
      min-width: 100%!important;
    }
    .main-card{
      border-radius: 24px 24px 0px 0px;
      width: 100vw!important;
      max-width: unset;
      flex:unset;
      // max-height: calc(100vh - 100px);
      max-height: unset;
      height: 100%!important;
      // margin-top: 100px;
    }
    .navigate-btn{
      margin-bottom: 60px;
    }
    .logout-btn-build-profile{
      margin-bottom:60px!important;
    }
  form{
    
    .scroll-container{
      max-height: unset!important;
      
    }
  }
}
}

#basic_template{
  @media (max-width: 600px) {
    .template-cards{
      min-width: 50%;
      padding-bottom: 24px;
    }
  }
}

.event-card, .group-card{
  :hover{
    background-color: #F7F7F9;
    overflow: hidden;
  }
}