.NotLoggedInRoutes {
  position: relative;

  .bg-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 1920px;
      height: 100%;
      object-fit: cover;
    }
  }

  .not-log-in-row {
    position: absolute;
    right: 0;
    height: 100%;
    background: #FFF;
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.25);
    padding: 60px;
    max-width: 596px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 960px) {
      max-width: 435px;
    }

    @media (max-width: 600px) {
      max-width: 100%;
      background: transparent;
      box-shadow: none;
      padding: 30px;
    }

    .login-wrap,
    .sign-up-wrap {
      background: #FFF;
      display: flex;
      flex-direction: column;

      @media (max-width: 600px) {
        padding: 24px;
        border-radius: 12px;
        max-width: 387px;
      }

      .logo-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
      }
    }

    .login-wrap {
      .form-wrap {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .item-wrap2 {
          margin-top: 24px;
          display: flex;
          gap: 6px;

          span {
            &:nth-child(2) {
              cursor: pointer;
            }
          }
        }

        .item-wrap3 {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 10px;
        }

        .item-wrap4 {
          margin-top: 24px;
        }

        .item-wrap5 {
          margin-top: 24px;
          width: 100%;

          .primary-button {
            button {
              width: 100%;
            }
          }
        }
      }
    }

    .sign-up-wrap {
      align-items: center;

      .item-wrap1 {
        margin-top: 36px;
      }

      .item-wrap2 {
        display: flex;
        gap: 6px;
        margin-top: 24px;

        span {
          &:nth-child(2) {
            cursor: pointer;
          }
        }
      }

      .item-wrap3 {
        margin-top: 10px;
        width: 100%;
      }

      .item-wrap8 {
        margin-top: 24px;
        width: 100%;

        .primary-button {
          button {
            width: 100%;
          }
        }
      }

      .form-wrap {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;

        .item-wrap4 {
          display: flex;
          gap: 10px;

          .ant-form-item {
            flex: 1;
          }
        }
      }
    }
  }
}
