.header-container {
  @media (max-width: 600px) {
    margin: 0 !important;
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    z-index: 20;

  }

  .header-wrap {
    @media (max-width: 600px) {
      border-radius: 0 !important;
      padding: 16px 16px !important;
    }

    .header-item1 {
      .ant-input-affix-wrapper {
        border-radius: 12px;
        background: #F2F2F5;
        padding: 10px !important;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 600px) {
          display: none;
        }

        .ant-input-prefix {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;

          span {
            svg {
              width: 24px;
              height: 24px;
              fill: #85858C;
            }
          }
        }

        .ant-input {
          margin-left: 10px;

          &::placeholder {
            color: #B6B6BF;
          }
        }
      }

      .mobile-wrap {
        display: none;
        align-items: center;
        gap: 10px;

        @media (max-width: 600px) {
          display: flex;
        }

        .menu_icon {
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 44px;
            height: 44px;
          }
        }
      }
    }

    .header-item2 {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .link-item-wrap {
        display: flex;
        justify-content: center;
        align-items: center;

        .link-item {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          padding: 10px 14px;
          border-radius: 12px;
          background: #F3F5F600;
          cursor: pointer;
          transition: all 0.3s 0s ease-out;

          svg {
            path {
              transition: all 0.3s 0s ease-out;
            }
          }

          span {
            transition: all 0.3s 0s ease-out;
          }

          &:hover {
            background: #F3F5F6;

            svg {
              path {
                fill: #112A34;
              }
            }

            span {
              color: #112A34 !important;
            }
          }

          @media (max-width: 1226px) {
            padding: 10px;

            span {
              display: none;
            }
          }
        }
      }

      .user-info-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 12px;
        border: 1px solid black;
        padding: 10px 14px;
        cursor: pointer;
        transition: all 0.3s 0s ease-out;

        @media (max-width: 600px) {
          display: none;
        }

        .user-pic {
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        @media (max-width: 960px) {
          span {
            display: none;
          }
        }

        .action-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          svg {
            width: 14px;
            height: 14px;

            path {
              transition: all 0.3s 0s ease-out;
            }
          }
        }

        &:hover {
          border: 1px solid #112A34 !important;

          .ant-typography {
            color: #112A34 !important;
          }

          .action-icon {
            svg {
              path {
                fill: #112A34;
              }
            }
          }
        }
      }
    }
  }
}
