.sidebar-container {
  .sidebar-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.3s 0s ease-out;

    .sidebar-item-wrap1 {
      .col-logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-top: 8px;
        padding-bottom: 24px;
        border-bottom: 1px solid black;

        .logo-wrap {
          display: flex;
          align-items: center;
          gap: 12px;
          cursor: pointer;
        }

        .close-icon {
          display: flex;
          z-index: 10;
          justify-content: center;
          align-items: center;

          .is_close {
            height: 20px;
            width: 20px;
            border-radius: 4px;
            border: 1px solid black;
            justify-content: center;
            align-items: center;
            display: none;

            svg {
              width: 10px;
              height: 10px;
            }
          }
        }
      }

      .sidebar-link-wrap {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .sidebar-link-item {
          border: 1px solid black;
          border-color: transparent;

          .menu-text {
            color: #6D6D73 !important;
          }

          .icon {
            svg {
              path {
                fill: #5C5C5C !important;
              }
            }

            &.selected {
              svg {
                path {
                  fill: #FFFFFF !important;
                }
              }
            }
          }

          &:hover {
            background: #F3F5F6;
            border-color: #F3F5F6;


            .icon {
              svg {
                path {
                  fill: #112A34 !important;
                }
              }

              &.selected {
                svg {
                  path {
                    fill: #fff !important;
                  }
                }
              }
            }

            //.anticon {
            //  color: #112A34;
            //}

            .menu-text {
              color: #112A34 !important;
            }
          }

          &.active-item {
            background: #112A34;
            border-color: #112A34;

            .anticon {
              color: #FFFFFF;
            }

            .menu-text {
              color: #FFFFFF !important;
            }
          }
        }
      }
    }

  }

  .sidebar-bottom {
    @media (max-width: 600px) {
      margin-bottom: 85px;
    }
  }

  &.sidebar_is_close {
    flex: 0 0 calc(72px) !important;
    min-width: calc(72px) !important;
    max-width: calc(72px) !important;
    width: calc(72px) !important;

    @media (max-width: 1440px) {
      flex: 0 0 calc(72px) !important;
      min-width: calc(72px) !important;
      max-width: calc(72px) !important;
      width: calc(72px) !important;
    }

    @media (max-width: 960px) {
      flex: 0 0 calc(72px) !important;
      min-width: calc(72px) !important;
      max-width: calc(72px) !important;
      width: calc(72px) !important;
    }

    .sidebar-wrap {

      .sidebar-item-wrap1 {
        .col-logo {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          .logo-wrap {
            span {
              display: none;
            }
          }

          .close-icon {
            position: absolute;
            left: 49px;

            .is_open {
              display: none;
            }

            .is_close {
              display: flex;
            }
          }
        }

        .sidebar-link-wrap {
          .sidebar-link-item {
            justify-content: center;

            .menu-text {
              display: none;
            }
          }
        }
      }
    }
  }
}

.mobile-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  pointer-events: none;
  opacity: 0;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background: rgba(13, 13, 13, 0.25);
  }

  .mobile-menu-row {
    position: relative;
    min-width: 291px;
    max-width: 291px;
    height: 100vh;
    border-right: 1px solid #F2F2F5;
    background: #FFF;
    padding: 16px;
    display: flex;
    flex-direction: column;

    .col-logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .logo-wrap {
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        padding: 15px 0;
        border-bottom: 1px solid #F2F2F5;
      }
    }

    .flex-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .mobile-menu-wrap {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 16px;

        .mobile-link-item {
          border: 1px solid black;
          border-color: transparent;

          .anticon {
            color: #6D6D73;
          }

          .menu-text {
            color: #6D6D73 !important;
          }

          &:hover {
            background: #F3F5F6;
            border-color: #F3F5F6;

            .anticon {
              color: #112A34;
            }

            .menu-text {
              color: #112A34 !important;
            }
          }

          &.active-item {
            background: #F3F5F6;
            border-color: #F3F5F6;

            .anticon {
              color: #112A34;
            }

            .menu-text {
              color: #112A34 !important;
            }
          }
        }
      }

      .bottom-item-wrap {
        border-top: 2.2px solid #CECED9;
        padding-top: 16px;

        ul {
          border: none !important;
          display: flex;
          flex-direction: column;
          align-items: start;
          gap: 20px;

          &::before,
          &::after {
            display: none !important;
          }

          li {
            margin: 0 !important;
            padding: 9px 14px !important;
            gap: 10px;

            &:hover {
              background-color: transparent !important;
            }

            .ant-menu-item-icon {
              font-size: 24px !important;
              color: #85858C !important;
            }

            .ant-menu-title-content {
              margin: 0 !important;
              color: #85858C !important;
            }
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    transition: all 0.22s 0s ease-out;

    .mobile-menu-row {
      transform: translateX(-13px);
      transition: all 0.22s 0s ease-out;
    }

    &.MobileMenuOpen {
      pointer-events: all;
      opacity: 1;

      .mobile-menu-row {
        transform: translateX(0);
      }
    }
  }
}

.mobile-bottom-header {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: 9px 18px;
  background: #FFFFFF;
  border-top: 1px solid #F2F2F5;

  .icon {
    svg {
      path {
        fill: #5C5C5C !important;
      }
    }

    &.selected {
      svg {
        path {
          fill: #112A34 !important;
        }
      }
    }
  }

  .mobile-menu-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 328px;

    .mobile-link-item {
      &::after {
        display: none;
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: #112A34;
      }

      svg {
        width: 24px;
        height: 24px;
      }

      &.active-item {
        &::after {
          display: flex;
        }
      }
    }
  }
}

.menu-item {
  .menu-text {
    color: #6D6D73 !important;

    &.active {
      color: white !important;
    }
  }

  .icon {
    svg {
      path {
        fill: #5C5C5C !important;
      }
    }

    &.selected {
      svg {
        path {
          fill: #fff !important;
        }
      }
    }
  }

  &:hover {
    background-color: #F3F5F6 !important;

    .menu-text {
      color: #112A34 !important;
    }

    .icon {
      svg {
        path {
          fill: #112A34 !important;
        }
      }

      &.selected {
        svg {
          path {
            fill: #fff !important;
          }
        }
      }
    }
  }
}

.mobile-menu {
  @media (max-width: 395px) {
    justify-content: space-between;
  }
  @media (min-width: 395px) {
    justify-content: center;
  }
}
