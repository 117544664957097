@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&family=Nunito+Sans:wght@200;300;400;500;600;700;800;900&display=swap');

.App {
  // text-align: center;
  background-color: #171717;
}

body {
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: #33421f66 transparent;
  /* scroll thumb and track */
  background-color: #ffffff;
  width: 100%;
}

.scroll-container {
  overflow-y: auto;
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* hide scrollbar for chrome, safari and opera */
.scroll-container::-webkit-scrollbar {
  display: none;
}

.hide-overflow {
  width: 100% !important;
  overflow-x: hidden !important;
}

.clickable {
  cursor: pointer;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
  display: flex;
}

// .ant-menu-vertical .ant-menu-item {
//   display: flex !important;
//   align-items: center;
//   img {
//     margin-bottom: 5px;
//   }
// }
// .sidebar-menu .ant-menu-vertical  .ant-menu-item {
//   display: flex !important;
//   align-items: center;
//   img {
//     margin-bottom: 5px;
//   }
// }
/////////////////////////////////////////////////////////////////////////////
/// ////////////////////////////////////////////////////////////////////////
/// ANTD overrided classes//////////////////////////////////////////////////
/// ///////////////////////////////////////////////////////////////////////
/// ///////////////////////////////////////////////////////////////////////
.ant-layout .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

//.ant-menu-item-selected {
//  svg {
//    fill: black;
//    //stroke: black;
//
//    path {
//      fill: black;
//      // stroke: black;
//    }
//  }
//}
.ant-segmented-group {
  border: 1px solid #333;
  border-radius: 4px;
  margin-right: -1px;
}

.ant-segmented-item {
  border-radius: 0px !important;
  border-right: 1px solid #333;
}

.ant-segmented .ant-segmented-item-label {
  margin-top: 5px;
}

//////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////
/// ///////////////////////////ANTD Overrided classes/////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////
.expand-icon {
  display: none;
  transition: opacity 0.3s;
}

.grid-img-tiles {
  cursor: pointer;
}

.grid-img-tiles:hover .expand-icon {
  display: block;
  opacity: 1;
}

.ellipse-svg-login {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.6;
}

@font-face {
  font-family: 'NewsGothicStd';
  src: local('NewsGothicStd'),
  url('../fonts/NewsGothicStd.otf') format('OpenType');
}

@font-face {
  font-family: 'NewsGothicStdBold';
  src: local('NewsGothicStdBold'),
  url('../fonts/NewsGothicStd-Bold.otf') format('OpenType');
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: local('PlusJakartaSans'),
  url('../fonts/PlusJakartaSans.ttf') format('TrueType');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('../fonts/Poppins.ttf') format('TrueType');
}

@font-face {
  font-family: 'Poppins-Italic';
  src: local('Poppins-Italic'), url('../fonts/Poppins-Italic.ttf') format('TrueType');
}

.home-images-grid {
  max-height: 84vh;

  @media screen and (max-height: 788px) {
    max-height: 80vh;
  }
}

.ant-select .ant-select-arrow {
  color: #4a4a4a;
}

.ant-select-focused:where(.css-dev-only-do-not-override-9s33v3).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #4a4a4a !important;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  width: 100%;
  height: 64px;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
  border-color: #4adc8c;
}

.ant-upload-wrapper {
  display: grid;
}

.select-image:hover {
  cursor: pointer;
  border: 2px solid #3cab6e;
}

.gradient-text {
  background: linear-gradient(90deg, #4adc8c 0%, #4adcca 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dashboard-hero {
  background: linear-gradient(0deg,
          rgba(12, 13, 14, 0.84) 0%,
          rgba(12, 13, 14, 0.84) 100%),
  url('../images/logoBg.jpg'),
  lightgray -570.069px -490.871px / 150.819% 414.661% no-repeat;
  background-size: cover;
}

.auth-sider {
  background: url('../images/programmes_hero.png') no-repeat;
  background-blend-mode: color;
  background-size: cover;
}

.bg-1 {
  background: url('../images/bg_1.jpg'), lightgray -191.729px -149.276px / 89.1% 136.858% no-repeat;
  background-size: cover;
}

.bg-2 {
  background: url('../images/bg_2.jpg'), lightgray 0px -341.888px / 71.25% 184.417% no-repeat;
  background-size: cover;
}

.bg-3 {
  background: url('../images/bg_3.jpg'), lightgray -191.729px -149.276px / 89.1% 136.858% no-repeat;
  background-size: cover;
}

.bg-4 {
  background: url('../images/bg_4.jpg'), lightgray -191.729px -149.276px / 89.1% 136.858% no-repeat;
  background-size: cover;
}

.bg-5 {
  background: url('../images/bg_5.jpg'), lightgray -191.729px -149.276px / 89.1% 136.858% no-repeat;
  background-size: cover;
}

.bg-6 {
  background: url('../images/bg_6.jpg'), lightgray 0px -341.888px / 71.25% 184.417% no-repeat;
  background-size: cover;
}

.bg-7 {
  background: url('../images/bg_7.jpg'), lightgray 0px -341.888px / 71.25% 184.417% no-repeat;
  background-size: cover;
}

.bg-8 {
  background: url('../images/bg_8.jpg'), lightgray 0px -341.888px / 71.25% 184.417% no-repeat;
  background-size: cover;
}

.card-no-padding {
  .ant-card-body {
    padding: 0;
  }
}

.card-sm-padding {
  .ant-card-body {
    padding: 0.5em;
  }
}

.card-md-padding {
  .ant-card-body {
    padding: 12px;
  }
}

.ant-carousel .slick-slide img {
  border-radius: 8px;
}

.dot-class {
  border-radius: 100%;
}

.ant-divider-horizontal {
  margin: 12px 0;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
}

.ant-table-tbody {
  cursor: pointer;
}

.ant-modal {
  border-radius: 12px;
}

.ant-picker-ok {
  .ant-btn-primary {
    background-color: #112A34;
  }

  .ant-btn-primary:hover {
    border: 1px solid #112A34;
    color: #112A34 !important;

    svg {
      stroke: #112A34;
    }
  }

  .ant-btn-primary:disabled {
    border: 1px solid #112A34AA;
    background-color: #112A34AA;
    color: white !important;

    svg {
      stroke: #112A34;
    }
  }
}

.primary-button {
  .ant-btn-primary:hover {
    border: 1px solid #112A34;
    color: #112A34 !important;

    svg {
      stroke: #112A34;
    }
  }
}

.primary-button-disabled {
  .ant-btn-primary:hover {
    border: 1px solid #112A34;
    color: #f2f2f5 !important;

    svg {
      stroke: #f2f2f5;
    }
  }
}

.secondary-button {
  .ant-btn-primary:hover {
    border: 1px solid #F3F5F6;
    color: #112A34 !important;

    svg {
      stroke: #112A34;
    }
  }
}

.secondary-button-disabled {
  .ant-btn-primary:hover {
    border: 1px solid #F3F5F6;
    color: #112A34 !important;

    svg {
      stroke: #112A34;
    }
  }
}

.error-button {
  .ant-btn-primary:hover {
    border: 1px solid #ea332d;
    color: #ea332d !important;

    svg {
      stroke: #ea332d;
    }
  }
}

.error-button-disabled {
  .ant-btn-primary:hover {
    border: 1px solid #ea332daa;
    color: #e2e2e2 !important;

    svg {
      stroke: #e2e2e2;
    }
  }
}

.progress-button {
  .ant-btn-primary:hover {
    border: 1px solid #FA8C16;
    color: #FA8C16 !important;

    svg {
      stroke: #FA8C16;
    }
  }
}

.progress-button-disabled {
  .ant-btn-primary:hover {
    border: 1px solid #FA8C16aa;
    color: #e2e2e2 !important;

    svg {
      stroke: #e2e2e2;
    }
  }
}

.outlined-button-disabled {
  .ant-btn-primary:hover {
    border: 1px solid #3cab6e;
    color: #3cab6e !important;

    svg {
      stroke: #3cab6e;
    }
  }
}

.primary-outlined-button-disabled {
  .ant-btn-primary:hover {
    border: 1px solid #8ACAD5;
    color: #8ACAD5 !important;

    svg {
      stroke: #8ACAD5;
    }
  }
}

.secondary-outlined-button-disabled {
  .ant-btn-primary:hover {
    border: 1px solid #4a4a4a;
    color: #4a4a4a !important;

    svg {
      stroke: #4a4a4a;
    }
  }
}

.hoverable-card {
  &:hover {
    background-color: #383838;
    //cursor: pointer;
  }
}

.creations-card {
  .ant-card-body {
    height: 100%;
  }

  .ant-tabs .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs .ant-tabs-tabpane {
    height: 94%;
  }
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 600;
}

.menu-item {
  .menu-text {
    color: #b3b3b3 !important;
  }

  svg {
    fill: #b3b3b3;
  }

  &:hover {
    .menu-text {
      color: white !important;
    }

    svg {
      fill: white;
    }
  }
}

.light-hover-tile:hover {
  background-color: #292929 !important;
}

.dark-hover-tile:hover {
  background-color: #171717 !important;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.no-show {
  display: none;
}

//input:-webkit-autofill,
//input:-webkit-autofill:hover,
//input:-webkit-autofill:focus,
//input:-webkit-autofill:active {
//  color: white !important;
//  transition: background-color 5000s ease-in-out 0s;
//}
.ant-select-dropdown {
  padding: 8px;
  border-radius: var(--Standard, 12px);
  border: 1px solid #F2F2F5;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03);

  .rc-virtual-list {
    .rc-virtual-list-holder {
      .rc-virtual-list-holder-inner {
        gap: 8px;

        .ant-select-item {
          padding: 9px 14px;

          .ant-select-item-option-content {
            color: #85858C;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }

          &.ant-select-item-option-selected {
            background: #F2F2F5;
          }
        }
      }
    }
  }
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: #112A34;
}


.activity-item {
  border: 1px solid #112A34;
  width: 100%;
  padding: 8px 15px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  cursor: pointer;

  .text {
    color: #112A34 !important;
  }

  &-selected {
    border: 1px solid #112A34;
    width: 100%;
    padding: 8px 15px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    cursor: pointer;
    background-color: #112A34;

    .text {
      color: white !important;
    }
  }

  &:hover {
    background-color: #F3F5F6;

    .text {
      color: #112A34 !important;
    }
  }
}

.url-item {
  &:hover {
    border-color: #112A34 !important;
    background-color: #F3F5F6;
  }
}

.choose-template {
  &:hover {
    border: 1px solid #112A34;
  }
}

.pagination-circle {
  width: 26px;
  height: 26px;
  background-color: #112A34;
  border-radius: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .ant-space-item {
    display: flex;
  }

  &.is_disabled {
    cursor: not-allowed;
    background-color: #F2F2F5;
  }

  .pagination-arrow {
    width: 16px;
  }
}

.user-card {
  &:hover {
    border: 1px solid #112A34;
  }
}

.edit-profile-tabs {
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs .ant-tabs-tab {
    justify-content: center;
    flex: 1
  }

  .ant-tabs > .ant-tabs-nav {
    margin-bottom: 20px;
  }
}

a.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan, .mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan .mapboxgl-canvas {
  width: 100% !important;
}

.mapboxgl-popup-content {
  padding: 12px !important;
  border-radius: 10px !important;
}

.event-popup {
  .mapboxgl-popup-content {
    padding: 0 !important;
  }
}
